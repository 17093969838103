<template>
  <v-container data-app  grid-list-xl fluid style="margin-top:1vh;">
   <v-layout row wrap>
     <v-flex>
       <BaseWidget title="Relatório Receber">
       <v-layout row wrap>
         <v-row align="center">
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
            style="text-align:left"
          >
            <v-label style="margin-top:10px"><b>Pesquisar por:</b></v-label>
            <v-select
              v-model="pesquisaSelecionado"
              :items="listaPesquisa"
              solo
            ></v-select>
          </v-col>

          <v-col
            class="d-flex"
            cols="12"
            sm="6"
          >
          <v-label><b style="color:transparent">P</b></v-label>
           <v-text-field
            :label="'Digite o código do(a) '+ pesquisaSelecionado"
            :disabled="!pesquisaSelecionado"
            v-if="pesquisaSelecionado != 'Transportador'"
            solo
            v-model="tfPesquisa"
          ></v-text-field>
          <v-autocomplete
            auto-select-first
            :label="'Selecione os dados do '+ pesquisaSelecionado"
            v-model="tfPesquisa"
            v-if="pesquisaSelecionado == 'Transportador'"
            :items="listaParticipantes"
            item-text="nome"
            item-value="cnpj"
            solo
            clearable
          ></v-autocomplete>
          </v-col>
          <br>
          <v-col
            class="d-flex"
            cols="12"
            sm="4"
            style="text-align:left;"
          ></v-col>
          
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
            style="text-align:left;margin:0.1%;padding:0%"
          >
            <v-label style="margin-top:10px"><b>Situação:</b></v-label>
              <v-select
                :items="listaSituacao"
                item-value="id"
                item-text="nome"
                persistent-hint
                v-model="situacaoSelecionada"
                solo
              ></v-select>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
            style="text-align:left;margin:0.1%;padding:0%"
          >
            <v-label style="margin-top:10px"><b>Tipo Data:</b></v-label>
              <v-select
                :items="listaTipo"
                item-value="nome"
                item-text="nome"
                v-model="tipoDataSelecionado"
                solo
              ></v-select>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="3"
            style="text-align:left;margin-left:1%;padding:0%"
          >
            <v-label style="margin-right:10px"><b>Período:</b></v-label>
            <div style="display:flex;align-items:center">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :disabled="tipoDataSelecionado == 'Nenhum'"
                  v-model="dateFormatted"
                  label="Inicio"
                  persistent-hint
                  append-icon="mdi-calendar"
                  v-bind="attrs"
                  @blur="date = parseDate(dateFormatted)"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker 
                v-model="date"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <p style="margin:2%">à</p>
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateFormatted2"
                    :disabled="tipoDataSelecionado == 'Nenhum'"
                    label="Fim"
                    persistent-hint
                    append-icon="mdi-calendar"
                    v-bind="attrs"
                    @blur="date2 = parseDate(dateFormatted2)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date2"
                  no-title
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </div>
          </v-col>
          <v-col
            class="d-flex"
            cols="12"
            sm="2"
            style="text-align:right;align-items:center;display:flex"
          >
            <div class="text-center mt-3">
              <v-btn dark rounded="" style="background-color:#757575" @click="filtrar()">
                <v-icon left>
                  mdi-filter
                </v-icon>
                <b>Filtrar</b>
              </v-btn>
            </div>
          </v-col>
           <v-col
            class="d-flex"
            cols="12"
            sm="2"
            style="position:relative;margin-top: 10px;"
          >
          <v-card
    class="mx-auto responsivo"
    max-width="300"
    tile
  >
        <v-list dense >
          <v-label><b style="font-size:1.8vh">Legenda</b></v-label>
          <v-list-item-group
            color="primary" 
          >
            <v-list-item
              v-for="(item, i) in legenda"
              disabled
              :key="i"
              
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon" :style="item.color"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text" style="color:#757575;text-align:left;margin-left:5%;font-size:1.5vh"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
  </v-card>
          </v-col>
          <v-col
          class="d-flex"
            cols="11"
            sm="5" style="margin:0%;padding:0%">
            <p style="text-align: left;font-size: 15px;"><b>*Periodo representa os últimos 60 dias</b></p>
          </v-col>
        </v-row>
       </v-layout>
       </BaseWidget>
     </v-flex>
   </v-layout>
   <v-layout row wrap>
      <v-flex xs12>
        <v-toolbar style="background-color:#808080" class="elevation-1" >
          <v-toolbar-title >
            <h5 style="color:white">Lista Receber</h5>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-flex xs5 md2 >
            <h5 style="color:white">Quantidade: {{this.quantidade}}</h5>
          </v-flex> 
          <v-flex xs5 md2 >
            <h5 style="color:white">Valor Titulos: {{this.valorTotal.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</h5>
          </v-flex> 
          <v-flex xs5 md2 >
            <h5 style="color:white">Valor Descontos: {{this.valorDesconto.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</h5>
          </v-flex> 
          <v-flex md2>
            <h5 style="color:white">Valor Devedor: {{this.valorDevedor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</h5>  
          </v-flex>
          <v-flex xs5 md2 >
              <v-btn dark rounded="" style="background-color:#757575" :disabled="this.listaCtes.length == 0" @click="botaoDownload()"><b>Download</b></v-btn>
            </v-flex>         
        </v-toolbar>
         <v-skeleton-loader v-if="showLoader"
            type="table"
        ></v-skeleton-loader>
        <v-flex class="p-4 white"  color="white">
          <template class="p-5">
            <v-data-table
              :headers="headers"
              :items="listaCtes"
              :single-expand="singleExpand"
              show-expand
              item-key="idConhecimento"
              class="elevation-1"
>
              <template v-slot:item.situacao="{ item }">
                <v-chip
                  class="text-center"
                  style="font-size: 0.72rem"
                  :color="getColor(item.situacao)"
                  dark
                >{{ item.situacao }}
                </v-chip>
              </template>
             <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <div style="padding:1%">
                    <td :colspan="headers.length">
                      <h3 style="margin-top:1%;text-align:left">Detalhes Receber</h3>
                      <div style="padding:0%;text-align:left">                 
                        <p>Entrega: {{item.idConhecimento}}</p>
                        <p>Tipo Entrega: {{item.tipoEntrega}}</p>
                        <p>Cnpj do Transportador: {{item.cnpjTransp.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")}}</p>
                        <p>Motorista: {{item.motorista}}</p>
                        <p>Placa: {{item.placa}}</p>
                        <p>Título: {{item.numTitulo}}</p>
                        <p>Valor Frete: {{item.valorFrete.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</p>
                        <p>Valor Titulo: {{item.valorTitulo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</p>
                        <p>Valor Desconto: {{item.valorDesconto.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</p>
                        <p>Valor Devedor: {{item.valorDevedor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}}</p>
                        <p>Integrado: {{item.integrado?'Sim':'Não'}}</p>
                        <p>Chaves CT-es: {{item.chavesDetalhesCte.join(' | ')}}</p>
                      </div>
                    </td>
                  </div>
                </td>
              </template>
            </v-data-table>
          </template>
        </v-flex>
      </v-flex>
    </v-layout>
 </v-container>
</template>

<script>
import BaseWidget from '../components/BaseWidget'
import Movimentacao from '../service/movimentacao'
import Cte from '../service/cte'
import { mapActions, mapMutations } from "vuex";
import print from 'print-js'
import xlsx from "json-as-xlsx"
export default {
  name: 'Dashboard',
  components: {
BaseWidget
  },
  data(){
    return{
      mensagem:'',
      headers: [
        { align: "start", sortable: false },
        { text: "Entrega", align: "left", value: "idConhecimento" },
        { text: "Transportador", align: "left", value: "transp" },
        { text: "Pagador", value: "filialText", align: "left" },
        { text: "Num CTe", value: "numDocs", align: "left" },
        { text: "Data Emissão", value: "dataEmissao", align: "left" },
        { text: "Data Prevista", value: "dataPrevista", align: "left" },
        { text: "Data Pagamento", value: "dataPagamento", align: "left" },
        { text: "Valor Frete", value: "valorFreteFormatted", align: "left" },
        { text: "Valor Título", value: "valorTituloFormatted", align: "left" },
        { text: "Status", value: "situacao", align: "left" },
        { text: 'Detalhes', value: 'data-table-expand' },
      ],
      listaCtes:[
      ],
      quantidade:0,
      valorTotal:0,
      valorDesconto: 0,
      valorDevedor: 0,
      listaPesquisa:[
        "Transportador",
        "Entrega",
        "Pagador",
        "Placa"
      ],
      listaSituacao:[ 
        {id:11, nome:"Todos"},
        {id:0,nome:"Aberto"},
        {id:1,nome:"Trânsito"},
        {id:99,nome:"Pago"},
        
      ],
      listaEntrega:[ 
        {id:1, nome:"Loja Grupo Mateus"},
        {id:2,nome:"Entrega Externa"},
        {id:3,nome:"Retorno Logístico"},
        
      ],
      listaTipo:[ 
        {id:11, nome:"Nenhum"},
        {id:0,nome:"Emissão"},
        {id:1,nome:"Prevista"},
        {id:2,nome:"Pagamento"},
      ],
      listaParticipantes:[],
      legenda: [

        { text: 'ABERTOS', icon: 'mdi-checkbox-blank-circle', color:'color:#1565C0; font-size:15px' },
        { text: 'PAGO', icon: 'mdi-checkbox-blank-circle', color:'color:#068206; font-size:15px' },
        { text: 'TRÂNSITO', icon: 'mdi-checkbox-blank-circle', color:'color:#F44336; font-size:15px' }
      ],
      pesquisaSelecionado:'',
      tfPesquisa:'',
      selecaoFiltro:{
        campo:'',
        valor:'',
        tipoData:'',
        dtInicio: null,
        dtFim: null,
        situacao:null,
        transportadores:[]
      },
      fieldsXls: [
          {value: "CNPJ",label: "CNPJ"},
          {value: "Nome Fantasia",label: "Nome Fantasia"},
          {value: "Tipo",label: "Tipo"},
          {value: "Chave Acesso",label: "Chave Acesso"},
          {value: "Num Documento",label: "Num Documento"},
          {value: "Série",label: "Série"},
          {value: "Data Emissão CTe",label: "Data Emissão CTe"},
          {value: "Placa",label: "Placa"}, 
          {value: "Origem",label: "Origem"}, 
          {value: "Destino",label: "Destino"}, 
          {value: "CFOP",label: "CFOP"}, 
          {value: "CST",label: "CST"}, 
          {value: "Valor Total",label: "Valor Total"}, 
          {value: "Valor ICMS",label: "Valor ICMS"}, 
          {value: "Entrega",label: "Entrega"}, 
          {value: "Título",label: "Título"}, 
          {value: "Pagador",label: "Pagador"}, 
          {value: "Data Emissão Título",label: "Data Emissão"}, 
          {value: "Data Prevista",label: "Data Prevista"}, 
          {value: "Data Pagamento",label: "Data Pagamento"}, 
          {value: "Valor Título",label: "Valor Título"},
          {value: "Valor Desconto",label: "Valor Desconto"},
          {value: "Valor Devedor",label: "Valor Devedor"},
          {value: "Status",label: "Status"}
        ],
      showLoader:true,
      situacaoSelecionada:11,
      tipoDataSelecionado:'Nenhum',
      situacaoSelecionadaRelatorio:11,
      tipoFiltroRelatorio:'',
      filtroRelatorio:'',
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateFormatted2: this.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
      menu1: false,
      menu2: false,
      singleExpand:true,
    }
  },
  mounted(){
    Movimentacao.getAllParticipantes().then(response=>{
      this.listaParticipantes = response.data
    }).catch(()=>{
        window.location.reload()
    })
    this.showLoader = false
    var doSave = e=>{
         if((e.ctrlKey || e.metaKey) && (e.key == "p" || e.charCode == 16 || e.charCode == 112 || e.keyCode == 80) ){
                this.printTable();
                e.cancelBubble = true;
                e.preventDefault();
                e.stopImmediatePropagation();
          }
       }
    document.addEventListener("keydown", doSave);   
  },
  computed:{
    computedDateFormatted () {
            return this.formatDate(this.date)
    },
    computedDateFormatted2 () {
            return this.formatDate(this.date2)
    },
  },
   watch: {
      date () {
        this.dateFormatted = this.formatDate(this.date)
      },
      date2(){
        this.dateFormatted2 = this.formatDate(this.date2)
      }
    },
  methods:{
    ...mapActions(["snackSuccess", "snackError"]),
    ...mapMutations(["setLoading"]),
    filtrar(){
      this.showLoader = true
      this.situacaoSelecionadaRelatorio = this.situacaoSelecionada
      if(this.tfPesquisa != ''){
        this.selecaoFiltro.campo = this.pesquisaSelecionado
        this.selecaoFiltro.valor = this.tfPesquisa
        this.tipoFiltroRelatorio = this.pesquisaSelecionado
        this.filtroRelatorio = this.tfPesquisa
      }else{
        this.selecaoFiltro.campo = ''
        this.selecaoFiltro.valor = ''
        this.tipoFiltroRelatorio = ''
        this.filtroRelatorio = ''
      }
      this.selecaoFiltro.transportadores = this.listaParticipantes
      this.selecaoFiltro.situacao = this.situacaoSelecionada
      this.selecaoFiltro.dtInicio = this.date
      this.selecaoFiltro.dtFim = this.date2
      this.selecaoFiltro.tipoData = this.tipoDataSelecionado
      this.listaCtes = []
        this.valorDesconto = 0;
        this.valorDevedor = 0;
        this.quantidade = 0;
        this.valorTotal = 0;
      Cte.getFilteredDocuments(this.selecaoFiltro).then(response=>{
        this.showLoader = false
        response.data.forEach(item =>{
        // this.teste.forEach(item =>{  
            this.listaCtes.push({
              idConhecimento: item.idConhecimento,
              idFilial:item.filial.id,
              filial: item.filial.descricao,
              filialText: item.filial.id+' - '+item.filial.descricao,
              transp:item.transp.descricao,
              cnpjTransp:item.transp.cnpj,
              motorista:item.motorista,
              placa:item.placa,
              numTitulo: item.numTitulo?item.numTitulo:'-',
              situacao:this.getStatus(item.status),
              tipoEntrega:this.getEntrega(item.tipoEntrega),
              valorFrete: item.valorFrete?item.valorFrete:0,
              valorTitulo: item.valorTitulo?item.valorTitulo:0,
              valorDesconto:item.valorDesconto?item.valorDesconto:0,
              valorDevedor:item.valorDevedor?item.valorDevedor:0,
              valorFreteFormatted:item.valorFrete?item.valorFrete.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}):'-',
              valorTituloFormatted: item.valorTitulo? item.valorTitulo.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}): '-',
              valorDescontoFormatted:item.valorDesconto? item.valorDesconto.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}): '-',
              valorDevedorFormatted:item.valorDevedor? item.valorDevedor.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}): '-',
              dataEmissao: item.dataEmissao ? this.formatDate(new Date(item.dataEmissao).toISOString().substr(0, 10)):'-',
              dataPrevista: item.dataPrevista?this.formatDate(new Date(item.dataPrevista).toISOString().substr(0, 10)):'-',
              dataPagamento: item.dataPagamento?this.formatDate(new Date(item.dataPagamento).toISOString().substr(0, 10)):'-',
              dataConhecimento: item.dataConhecimento?this.formatDate(new Date(item.dataConhecimento).toISOString().substr(0, 10)):'-',
              integrado:item.integrado,
              chavesCte: item.cte?item.cte:[],
              chavesDetalhesCte:[],
              numDocs:''
            })
          })
        this.quantidade = this.listaCtes.length;
        this.listaCtes.forEach(item=>{
          this.valorDesconto += item.valorDesconto
          this.valorDevedor +=item.valorDevedor
          this.valorTotal +=item.valorTitulo
          var num = []
          item.chavesCte.forEach(nota=>{
              item.chavesDetalhesCte.push(nota.numDoc+" - "+nota.chaveAcesso)
              num.push(nota.numDoc)
          })
          item.numDocs = item.chavesCte.length > 2 ? 'Ver Detalhes': num.join(' | ')
        })
      }).catch((err)=>{
        console.log(err)
        this.showLoader = false
      })
    },
    botaoDownload(){
      this.situacaoSelecionadaRelatorio = this.situacaoSelecionada
      if(this.tfPesquisa != ''){
        this.selecaoFiltro.campo = this.pesquisaSelecionado
        this.selecaoFiltro.valor = this.tfPesquisa
        this.tipoFiltroRelatorio = this.pesquisaSelecionado
        this.filtroRelatorio = this.tfPesquisa
      }else{
        this.selecaoFiltro.campo = ''
        this.selecaoFiltro.valor = ''
        this.tipoFiltroRelatorio = ''
        this.filtroRelatorio = ''
      }
      this.selecaoFiltro.transportadores = this.listaParticipantes
      this.selecaoFiltro.situacao = this.situacaoSelecionada
      this.selecaoFiltro.dtInicio = this.date
      this.selecaoFiltro.dtFim = this.date2
      this.selecaoFiltro.tipoData = this.tipoDataSelecionado
      this.showLoader = true;
      Cte.downloadXLSX(this.selecaoFiltro).then(response=>{
        this.download(response.data)
        this.showLoader = false;
      }).catch(()=>{
        this.showLoader = false;
      })
    },
    download(data){
          let table = [
          {
            sheet: "Relatorio",
            columns: this.fieldsXls,
            content: this.tsvJSON(data),
          },
        ]
        let settings = {
          fileName: "Relatório - "+  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toLocaleDateString().substr(0, 10),
          extraLength: 3,
          writeMode: "writeFile",
          writeOptions: {}, 
          RTL: false,
        }
        xlsx(table, settings)
    },
    tsvJSON(tsv){
        var lines=tsv.split("\r");
        var result = [];
        var headers=lines[0].split("|");
        for(var i=1;i<lines.length;i++){
            var obj = {};
            var currentline=lines[i].split("|");
            for(var j=0;j<headers.length;j++){
                obj[headers[j]] = currentline[j]? currentline[j].replace(".",","):''
            }
            result.push(obj);
        }
        return result;
    },
    toArrayBuffer(data) {
      var binaryLen = data.length;
      var bytes = new Uint8Array(binaryLen);
      for (var i = 0; i < binaryLen; i++) {
        var ascii = data.charCodeAt(i);
        bytes[i] = ascii;
      }
      return bytes;
    },

    formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
    parseDate (date) {
        if (!date) return null

        const [day,month,  year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    getEntrega(id){
      var retorno = ''
      this.listaEntrega.forEach(item=>{
        if(item.id == id){
         retorno = item.nome
        }
      })
      return retorno
    },
    getColor(status) {
      if (status == "Aberto" ) {
        return "#1565C0";
      } else if (status == "Pago") {
        return "#068206";
      } else if (status == "Trânsito") {
        return "#F44336";
      }
    },
    getStatus(status) {
      if (status == 0) {
        return "Aberto";
      } else if (status == 99) {
        return "Pago";
      } else if (status == 1) {
        return "Trânsito";
      } 
    },
    printTable(){
      let titulo = 'WorkCTE - Relatórios Receber'
      let datas = ''
      let valores = ' <b>Valor Total:</b> '+ this.valorTotal + ' <b>Total Desconto:</b> '+this.valorDesconto+' <b>Total de Registros: </b>'+this.listaCtes.length
      let filtro = ''

      print(
        {
          printable: this.listaCtes,
           properties: [
             {field: 'idConhecimento', displayName: 'Conhecimento'},
             {field: 'tipoEntrega', displayName: 'Entrega'},
             {field: 'transp', displayName: 'Transportador'},
             {field: 'filial', displayName: 'Pagador'},
             {field: 'numTitulo', displayName: 'Título'},
             {field: 'dataEmissao', displayName: 'Emissão'},
             {field: 'dataPrevista', displayName: 'Prevista'},
             {field: 'dataPagamento', displayName: 'Pagamento'},
             {field: 'valorTitulo', displayName: 'Valor'},
             {field: 'placa', displayName: 'Placa'},
             {field: 'situacao', displayName: 'Status'},
             ],
            type: 'json',
            header:'<h1 class="custom-h1">'+titulo+'</h1><h2 class="custom-h2">Relatório'+datas+'</h2><h3 class="custom-h3">'+filtro+'</h3><br/>'+valores,
            style: '.custom-h1 { color: #757575; text-align:center; margin-bottom:1px }.custom-h2 { color: #757575; text-align:center; }.custom-h3 { color: #757575; text-align:center; }', 
            gridHeaderStyle: 'color: black;  border: 2px solid black;font-size:11px',
            gridStyle: ' border: 2px solid black;font-size:11px;text-align:center'
            }
        )
    },
  }
}
</script>
<style lang="scss" scoped>

.vue-treeselect__control {
  border-radius: 0;
}

.responsivo{
    position: absolute;
    top:-100%;
    width: 100%;
    @media only screen and (max-width: 768px) {
      /* For mobile phones: */
      position:relative;
      top:0%;
    }
}
</style>
